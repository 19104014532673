import { useEffect, useState } from "react"
import style from "../../../styles/control.module.css"
import ControlLeftTop from "./ControlLeftTop"
import ControlLeftList from "./ControlLeftList";
import { CommonAxiosToken } from "../../../module/CommonAxios";
import controlSlice from "../../../slices/control";
import { useDispatch } from "react-redux";

const ControlLeft = ({ setBusPop, setStopPop, positArr, routeData, setRoute, route }) => {

    const [check, setCheck] = useState(1);
    const [busList, setBusList] = useState([]);
    const [stopList, setStopList] = useState([]);
    const dispatch = useDispatch();
    const getData = () => {


        let sendData = {

            where: "",
        };
        if (route !== 0) {
            sendData.where = route
        }

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/control_select",
            sendData,
            function (result) {

                if (result.messageinfo) {

                    result.messageinfo.message.map((item) => {
                        dispatch(controlSlice.actions.setArr(
                            {
                                longitude: '',
                                latitude: '',
                                route_no: item.list_route_no,
                                list_no: item.no,
                                driver_order: 0,
                                state: 0,
                                nextStop: ""
                            }))
                    })



                    setBusList(result.messageinfo.message);
                    setStopList(result.messageinfo.message_data2);
                } else {

                }

            })
    }

    useEffect(() => {
        getData();
    }, [route])
    const clickCheck = (data) => {
        setCheck(data);
    }
    const changeSelect = (e) => {
        setRoute(e.target.value)
    }
    return (
        <div className={style.main_left_div}>
            <ControlLeftTop />
            <div className={style.search_wrap}>
                <div className={style.search_select}>
                    <select onChange={changeSelect}>
                        <option value={0}>전체 노선</option>
                        {routeData.map((item) => {
                            return <option value={item.no}>{item.route_name}</option>
                        })}
                    </select>
                </div>
                <div className={style.search_inner}>
                    <div className={style.search_box}>
                        <div >
                            <input type="text" />
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <button>초기화</button>
                </div>
            </div>


            <div className={style.left_list_wrap}>
                <ul>
                    <li onClick={() => clickCheck(1)} className={check === 1 ? `${style.left_list_li} ${style.left_list_check}` : style.left_list_li}>
                        <span>버스</span>
                    </li>
                    <li onClick={() => clickCheck(2)} className={check === 2 ? `${style.left_list_li} ${style.left_list_check}` : style.left_list_li}>
                        <span>정류장</span>
                    </li>
                </ul>
                <div>
                    <ControlLeftList setBusPop={setBusPop} setStopPop={setStopPop}
                        busList={busList} stopList={stopList} check={check} positArr={positArr} route={route} />
                </div>
            </div>
        </div>
    )
}

export default ControlLeft