import logo from './logo.svg';
// import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Default from './layout/Default';
import UseHeader from './layout/UseHeader';
import Login from './pages/Login';
// import "./styles/common.css";
import BusRouteLine from './pages/BusRouteLine';
import MainLayout from './layout/MainLayout';
import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import BusRouteInfo from './pages/BusRouteInfo';

import { useSelector } from 'react-redux';
import PrivateRoute from "./lib/route/PrivateRoute";
import BusRouteMg from './pages/BusRouteMg';
import BusStopMg from './pages/BusStopMg';
import BusStopInfo from './pages/BusStopInfo';
import AppNoticeMg from './pages/AppNoticeMg';
import AppQnaMg from './pages/AppQnaMg';
import AppNoticeInfo from './pages/AppNoticeInfo';
import AppQnaInfo from './pages/AppQnaInfo';
import BusMg from './pages/BusMg';
import BusCompanyMg from './pages/BusCompanyMg';
import AppUserMg from './pages/AppUserMg';
import AppUserInfo from './pages/AppUserInfo';
import AppFaqMg from './pages/AppFaqMg';
import AppFaqInfo from './pages/AppFaqInfo';
import BusCompanyInfo from './pages/BusCompanyInfo';
import AppTermMg from './pages/AppTermMg';
import AppFaqCaMg from './pages/AppFaqCaMg';
import BusRaceMg from './pages/BusRaceMg';
import StatisticsBusMg from './pages/StatisticsBusMg';
import StatisticsCallMg from './pages/StatisticsCallMg';
import StatisticsAdminMg from './pages/StatisticsAdminMg';
import OperateCallMg from './pages/OperateCallMg';
import OperateControlMg from './pages/OperateControlMg';
import OperateMoniterMg from './pages/OperateMoniterMg';
import PhonePage from './pages/PhonePage';
import OperateCallInfo from './pages/OperateCallInfo';
import OperateControlInfo from './pages/OperateControlInfo';
import OperateMoniterInfo from './pages/OperateMoniterInfo';
import MoniterPage from './pages/MoniterPage';
import { useEffect } from 'react';
import MainView from './pages/MainView';
import PrivateRouteTwo from './lib/route/PrivateRouteTwo';
import PrivateRouteThree from './lib/route/PrivateRouteThree';
import ControlBusPage from './pages/ControlBusPage';
import UseHeaderControl from './layout/UseHeaderControl';
import OperateStopMg from './pages/OperateStopMg';
import OperateNumberMg from './pages/OperateNumberMg';
function App() {
  const user = useSelector(state => state.user);

  useEffect(() => {
    console.log(JSON.stringify(user));
  }, [])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Default />}>
          {/* <Route path='/' element={<PhonePage />} /> */}
          <Route path="/" element={<LoginPage />} />
        </Route>

        <Route
          path=""
          element={
            <PrivateRouteTwo
              authenticated={user}
              component={<UseHeader />}
            />
          }
        >
          <Route path='/admin' element={<MainView />} />
          <Route path='/appmg/qnamg' element={<AppQnaMg />} />
          <Route path='/appmg/qnamg/qnaInfo/:idx' element={<AppQnaInfo />} />
          <Route path='/appmg/usermg' element={<AppUserMg />} />
          <Route path='/appmg/usermg/userInfo/:idx' element={<AppUserInfo />} />
          <Route path='/appmg/noticemg' element={<AppNoticeMg />} />
          <Route path='/appmg/noticemg/noticeInfo/:idx' element={<AppNoticeInfo />} />
          <Route path='/appmg/faqmg' element={<AppFaqMg />} />
          <Route path='/appmg/faqmg/faqInfo/:idx' element={<AppFaqInfo />} />
          <Route path="/appmg/divimg" element={<AppFaqCaMg />} />
          <Route path='/appmg/termmg/:idx' element={<AppTermMg />} />
          <Route path='/operatemg/call' element={<OperateCallMg />} />
          <Route path='/operatemg/admin' element={<OperateControlMg />} />
          <Route path='/operatemg/moniter' element={<OperateMoniterMg />} />
          <Route path='/operatemg/stop' element={<OperateStopMg />} />
          <Route path='/operatemg/number' element={<OperateNumberMg />} />
          <Route path='/operatemg/call/callInfo/:idx' element={<OperateCallInfo />} />
          <Route path='/operatemg/admin/adminInfo/:idx' element={<OperateControlInfo />} />
          <Route path='/operatemg/moniter/moniterInfo/:idx' element={<OperateMoniterInfo />} />

          <Route path='/busmg/stopmg' element={<BusStopMg />} />
          <Route path='/busmg/stopmg/stopInfo/:idx' element={<BusStopInfo />} />
          <Route path='/busmg/routemg' element={<BusRouteMg />} />
          <Route path='/busmg/routemg/routeInfo/:idx' element={<BusRouteInfo />} />
          <Route path='/busmg/routemg/busline/:idx' element={<BusRouteLine />} />
          <Route path='/busmg/busmg' element={<BusMg />} />
          <Route path='/busmg/companymg' element={<BusCompanyMg />} />
          <Route path='/busmg/companymg/companyInfo/:idx' element={<BusCompanyInfo />} />
          <Route path="/busmg/record" element={<BusRaceMg />} />
          <Route path="/statistics/bus" element={<StatisticsBusMg />} />
          <Route path="/statistics/call" element={<StatisticsCallMg />} />
          <Route path="/statistics/admin" element={<StatisticsAdminMg />} />

        </Route>

        <Route
          path=""
          element={
            <PrivateRouteTwo
              authenticated={user}
              component={<UseHeaderControl />}
            />
          }
        >
          <Route path="/control/bus" element={<ControlBusPage />} />
          <Route path="/control/race" element={<MoniterPage />} />


        </Route>

        <Route
          path=""
          element={
            <PrivateRoute
              authenticated={user}
              component={<MainLayout />}
            />
          }
        >

          {/* <Route path="" element={<MainLayout />}> */}
          <Route path="/moniter" element={<MoniterPage />} />
        </Route>



        <Route
          path=""
          element={
            <PrivateRouteThree
              authenticated={user}
              component={<MainLayout />}
            />
          }
        >
          <Route path="/main" element={<MainPage />} />
        </Route>



      </Routes></BrowserRouter>
  );
}

export default App;
