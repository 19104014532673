import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    carNo: "",
    routeNo: "",
    stopNo: "",
    routeName: "",
    routeNo2: "",
    carUniq: "",
    busList: [],
}
const controlSlice = createSlice({
    name: 'control',
    initialState,
    reducers: {

        setBusPop2(state, action) {
            state.carNo = action.payload.carNo;
            state.routeNo = action.payload.routeNo;
            state.carUniq = action.payload.carUniq;

        },
        setstopPop2(state, action) {
            state.stopNo = action.payload.stopNo;
        },
        setRoutePop2(state, action) {
            state.routeName = action.payload.routeName;
            state.routeNo2 = action.payload.routeNo2;

        },
        setArr(state, action) {

            state.busList.push(action.payload);
        },
        changeData(state, action) {
            let idIndex = state.busList.findIndex((item) => { return item.list_no === action.payload.list_no })
            state.busList[idIndex] = action.payload

        },
        reset(state, action) {
            state.carNo = "";
            state.routeNo = "";
            state.carUniq = "";
            state.stopNo = "";
            state.routeName = "";
            state.routeNo2 = "";
            state.busList = [];
        }


    },
    extraReducers: builder => { } //비동기 액션
});


export default controlSlice;