import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import styles from "./../styles/main.module.css";
import { Scrollbars } from 'react-custom-scrollbars';
import * as CommonAxios from "./../components/CommonAxios";
import io from 'socket.io-client';
// import axios from "axios";
import { FaRegCircleUser } from "react-icons/fa6";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
// import phone_icon from "./../assets/icons/phone_call.png";
// import bus_icon from "./../assets/icons/bus_call.png";
// import taxi_icon from "./../assets/icons/taxi_call.png";
import calendar from "./../assets/icons/calender.png";
import CustomSubTable from '../components/CustomSubTable';
// import RouteData from "./../pages/RouteData"
import { useNavigate } from "react-router-dom";
import bus from "./../assets/icons/bus_icon.png";
// import bus_stop from "./../assets/icons/bus_stop.png";
import stop_blue from "./../assets/icons/map_icon_color.png";
import stop_white from "./../assets/icons/map_icon.png";
// import call_icon from "./../assets/icons/call_icon.png";
import start_icon from "./../assets/icons/start_icon.png";
import end_icon from "./../assets/icons/end_icon.png";
import moment from "moment";
import Menu from '../components/leftMenu/Menu';
import CallMenu from '../components/leftMenu/CallMenu';
import RunModal from '../components/modal/RunModal';
import WorkModal from '../components/modal/WorkModal';

const { kakao } = window;

const MainPage = () => {

    const user = useSelector(state => state.user);

    const navigate = useNavigate();

    const [socket, setSocket] = useState();

    const [titleArr, setTitleArr] = useState([]);       // 가져온 노선명 배열 데이터
    const [sctTitle, setSctTitle] = useState("");       // 선택한 노선명 데이터

    const [routeArr, setRouteArr] = useState([]);       // 가져온 전체 노선 배열 데이터
    // const [sctRoute, setSctRoute] = useState([]);       // 선택한 노선 데이터

    const [stopArr, setStopArr] = useState([]);         // 노선이 존재하는 정류장 데이터
    const [allStop, setAllStop] = useState([]);         // 전체 정류장

    const [mapInfo, setMaps] = useState(null);          // 카카오 지도 데이터
    const [num, setNum] = useState(0);                  // 셀렉박스 첫 선택 이후 기존 마커 지우기 용도
    const [markerArr, setMarkerArr] = useState([]);     // 출발지, 경유지, 목적지 이름,위,경도 데이터 배열

    const [startMark, setStartMark] = useState([]);
    const [endMark, setEndMark] = useState([]);

    const [lineArr, setLineArr] = useState([]);

    const [typeVal, setTypeVal] = useState("bus");      // 왼쪽 메뉴 타입
    const [busVal, setBusVal] = useState([]);           // 버스 업무 처리 목록 배열
    const [taxiVal, setTaxiVal] = useState([]);         // 택시 업무 처리 목록 배열

    const [mapType, setMapType] = useState("bus");      // 지도 위 타입

    const [menuBool, setMenuBool] = useState(false);    // 정류장콜 메뉴 여부
    const [runModal, setRunModal] = useState(false);    // 수동 배차 모달창 여부

    const menuBoolRef = useRef(menuBool);

    const [modalData, setModalData] = useState();
    const [workModal, setWorkModal] = useState(false);

    useEffect(() => {
        if (user.id != '') {

            const container = document.getElementById('map');
            const options = {
                // center: new kakao.maps.LatLng(36.806744385094596, 127.80419313104726),
                // center: new kakao.maps.LatLng(36.63846052508691, 127.44473659298507),
                center: new kakao.maps.LatLng(36.809165585385955, 127.79723697515438),
                level: 6
            }

            const map = new kakao.maps.Map(container, options);
            setMaps(map);

            let sendData = {
                uniq: user.uniq
            };

            // 버스 노선 정보(노선이름, 노선정보, 공개여부)
            CommonAxios.CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/select_bus_route",
                sendData,
                function (result) {
                    if (result.messageinfo.state == "ok") {
                        let list = result.messageinfo.message;
                        let newArr = list.filter((item) => item.route_open == 1);
                        // console.log(list);
                        setTitleArr(newArr);
                    } else {
                        Swal.fire({
                            title: "조회 실패",
                            icon: "error",
                            confirmButtonText: "확인",
                        }).then((res) => {
                            if (res.isConfirmed) {
                            }
                        });
                    }
                }
            );

            // 버스 노선의 정류장 및 순서 정보
            CommonAxios.CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/select_bus_route_stop",
                sendData,
                function (result) {
                    if (result.messageinfo.state == "ok") {
                        let list = result.messageinfo.message;
                        // console.log(list);
                        setRouteArr(list);
                    } else {
                        Swal.fire({
                            title: "조회 실패",
                            icon: "error",
                            confirmButtonText: "확인",
                        }).then((res) => {
                            if (res.isConfirmed) {
                            }
                        });
                    }
                }
            );

            // 모든 정류장 정보(정류장명, 위-경도, 정류장번호)
            CommonAxios.CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/select_bus_stops",
                sendData,
                function (result) {
                    if (result.messageinfo.state == "ok") {
                        let list = result.messageinfo.message;
                        setStopArr(list);
                    } else {
                        Swal.fire({
                            title: "조회 실패",
                            icon: "error",
                            confirmButtonText: "확인",
                        }).then((res) => {
                            if (res.isConfirmed) {
                            }
                        });
                    }
                }
            );

            // 모든 정류장 정보(정류장명, 위-경도, 정류장번호)
            CommonAxios.CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/select_bus_stop_all",
                sendData,
                function (result) {
                    if (result.messageinfo.state == "ok") {
                        let list = result.messageinfo.message;
                        setAllStop(list);
                    } else {
                        Swal.fire({
                            title: "조회 실패",
                            icon: "error",
                            confirmButtonText: "확인",
                        }).then((res) => {
                            if (res.isConfirmed) {
                            }
                        });
                    }
                }
            );

            // 해당 관리자의 업무 처리 목록 조회
            CommonAxios.CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/select_oper_list_today",
                sendData,
                function (result) {
                    if (result.messageinfo.message != null) {
                        let getData = result.messageinfo.message;
                        setBusVal(getData);
                    } else {
                        Swal.fire({
                            title: "조회 오류",
                            text: result.messageinfo.message,
                            icon: "error",
                            confirmButtonText: "확인",
                            confirmButtonColor: "#FF0000",
                        });
                    }
                }
            );

            const newSocket = io.connect('http://yjview.kr:80');
            // const newSocket = io.connect('http://127.0.0.1:5000');
            // const newSocket = io.connect('http://192.168.35.133:5000');
            setSocket(newSocket);

            return () => {
                newSocket.disconnect();
                setSocket(null);
            }
        } else {
            navigate("/");
        }

    }, [])

    const [urls, setUrls] = useState("");             // 구루미 url주소
    const [callCode, setCallCode] = useState("");       // 콜 처리코드
    const [stopName, setStopName] = useState("");       // 정류장 고유번호
    const [sentDate, setSentDate] = useState("");       // 발신일시
    const [getDate, setGetDate] = useState("");         // 수신일시
    const [rIds, setRIds] = useState("");               // 삭제용 방id

    const [urlBool, setUrlBool] = useState(false);

    const [callData, setCallData] = useState("");
    const [callList, setCallList] = useState([]);

    const [modal, setModal] = useState(false);

    const onChangeTitle = useCallback((e) => {
        let getData = e.target.value;
        setSctTitle(getData);
    }, [])

    useEffect(() => {
        // 노선 선택
        if (sctTitle != "") {
            let mapData = titleArr.filter((no) => no.route_name == sctTitle);
            let sctNum = mapData[0].no;
            let routesArr = routeArr.filter((item) => item.rs_route_no == sctNum);
            let stopsArr = stopArr.filter((item) => item.rs_route_no == sctNum);

            console.log(mapData.length)
            console.log(routesArr)
            if (mapData.length > 0 && routesArr.length > 0) {
                // 기존의 선 지우기
                if (lineArr.length > 0) {
                    lineArr[0].setMap(null);
                }
                console.log(routesArr);

                const linepath = [];
                let longSum = 0;
                let latiSum = 0;

                routesArr.map((item) => {
                    linepath.push(new kakao.maps.LatLng(item.line_latitude, item.line_longitude));
                    longSum += item.line_longitude;
                    latiSum += item.line_latitude;
                })

                // 지도에 표시할 선을 생성합니다
                var polyline = new kakao.maps.Polyline({
                    path: linepath, // 선을 구성하는 좌표배열 입니다
                    strokeWeight: 5, // 선의 두께 입니다
                    strokeColor: '#FFAE00', // 선의 색깔입니다
                    strokeOpacity: 0.7, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                    strokeStyle: 'solid' // 선의 스타일입니다
                });

                // 지도에 선을 표시합니다 
                polyline.setMap(mapInfo);
                setLineArr([polyline]);

                let centerLng = longSum / routesArr.length;
                let centerLot = latiSum / routesArr.length;

                let move = new kakao.maps.LatLng(centerLot, centerLng);

                mapInfo.setCenter(move);

                let blueArr = stopsArr.filter((a) => a.rs_stop_essential == 1);
                let whiteArr = stopsArr.filter((a) => a.rs_stop_essential == 0)

                let positions1 = blueArr.map((item) => {
                    let data = new kakao.maps.LatLng(item.stop_latitude, item.stop_longitude);
                    return (
                        {
                            title: item.stop_name,
                            latlng: data
                        }
                    )
                })

                let positions2 = whiteArr.map((item) => {
                    let data = new kakao.maps.LatLng(item.stop_latitude, item.stop_longitude);
                    return (
                        {
                            title: item.stop_name,
                            latlng: data
                        }
                    )
                })

                let markers = [];

                // const imgSrc = bus_stop;
                const imgSrc1 = stop_blue;
                const imgSrc2 = stop_white;
                let imageSize = new kakao.maps.Size(30, 30);                         // 마커 이미지 사이즈
                let markerImage1 = new kakao.maps.MarkerImage(imgSrc1, imageSize);    // 마커에 이미지 등록
                let markerImage2 = new kakao.maps.MarkerImage(imgSrc2, imageSize);    // 마커에 이미지 등록

                // const imgSrc2 = bus;
                // let imageSize2 = new kakao.maps.Size(15,15);                         // 마커 이미지 사이즈
                // let markerImage2 = new kakao.maps.MarkerImage(imgSrc2, imageSize2);    // 마커에 이미지 등록

                // var busMarker = new kakao.maps.Marker({
                //     map: mapInfo,
                //     position: new kakao.maps.LatLng(36.638212740676984, 127.44472957793361),
                //     image: markerImage2
                // })

                // markers.push(busMarker);

                if (num > 0) {
                    let cnt = markerArr.length;
                    for (let i = 0; i < cnt; i++) {
                        markerArr[i].setMap(null);
                    }
                    if (positions1.length > 0) {
                        for (let i = 0; i < positions1.length; i++) {
                            var marker = new kakao.maps.Marker({
                                map: mapInfo,
                                position: positions1[i].latlng,
                                title: positions1[i].title,
                                image: markerImage1,
                            })
                            markers.push(marker);
                        }
                    }
                    if (positions2.length > 0) {
                        for (let i = 0; i < positions2.length; i++) {
                            var marker = new kakao.maps.Marker({
                                map: mapInfo,
                                position: positions2[i].latlng,
                                title: positions2[i].title,
                                image: markerImage2,
                            })
                            markers.push(marker);
                        }
                    }
                    setMarkerArr(markers);
                    setNum(num + 1);

                } else {

                    if (positions1.length > 0) {
                        for (let i = 0; i < positions1.length; i++) {
                            var marker = new kakao.maps.Marker({
                                map: mapInfo,
                                position: positions1[i].latlng,
                                title: positions1[i].title,
                                image: markerImage1,
                            })
                            markers.push(marker);
                        }
                    }
                    if (positions2.length > 0) {
                        for (let i = 0; i < positions2.length; i++) {
                            var marker = new kakao.maps.Marker({
                                map: mapInfo,
                                position: positions2[i].latlng,
                                title: positions2[i].title,
                                image: markerImage2,
                            })
                            markers.push(marker);
                        }
                    }

                    // for(let i = 0; i < positions.length; i++){
                    //     var marker = new kakao.maps.Marker({
                    //         map: mapInfo,
                    //         position: positions[i].latlng,
                    //         title: positions[i].title,
                    //         image: markerImage,
                    //     })
                    //     markers.push(marker);
                    // }
                    setMarkerArr(markers);
                    setNum(num + 1);
                }
                // markMove();
                // markers.setMap(mapInfo);



            }
        }
    }, [sctTitle])

    const [posits, setPosits] = useState();
    const [positArr, setPositArr] = useState([]);
    const [positMark, setPositMark] = useState([]);
    const [newWin, setNewWin] = useState(null);

    useEffect(() => {
        if (user) {
            if (!socket) return;
            console.log(user);
            socket.on('connect', () => {
                console.log("연결완료!");
                const socketId = socket.id;
                // console.log(socketId);
                if (user.type == 1) {
                    socket.emit('join', socketId);
                }

                socket.on('idss', ro => {
                    // console.log(ro);
                })
                socket.on('sends', ro => {
                    chkTest(ro)
                    // console.log(ro);
                })
            });

            socket.on('position', ro => {
                // console.log(ro);
                setPosits(ro);
            })
        }

    }, [socket, user])

    const chkTest = (ro) => {
        // console.log(urls);
        // console.log(menuBoolRef);
        // console.log(menuBoolRef.current);
        // 통신팝업 / 좌측 배차메뉴 / 구루미 팝업
        if (menuBoolRef.current) {

        } else {
            let data = {
                call_code: ro.call,
                stop_num: ro.stopNumber,
                urls: ro.roomUrlId,
                roomId: ro.roomId,
                date: ro.sentDate,
                // stop_name: filtering[0].stop_name
            }
            setCallData(data);
            setCallCode(ro.call);
            setStopName(ro.stopNumber);
            setSentDate(ro.sentDate);
            setGetDate(new Date());
            setUrls(ro.roomUrlId);
            setRIds(ro.roomId);
        }
    }

    useEffect(() => {
        if (callData != "") {

            let stops = allStop;
            let filtering = stops.filter((item) => item.stop_number == callData.stop_num);
            let newList = {
                call_code: callData.call_code,
                stop_num: callData.stop_num,
                urls: callData.urls,
                roomId: callData.roomId,
                date: callData.date,
                stop_name: filtering.length > 0 ? filtering[0].stop_name : "",
            }
            setCallList([...callList, newList])
            setCallData("");
        }
    }, [callData])

    // urls에 데이터 들어오면 10초 지속 alert창 생성 및 지나면 닫히도록 설정
    useEffect(() => {
        if (urls != "") {
            if (urlBool) {
                setModal(true);
                setMenuBool(true);
                setUrlBool(false);
            } else {
                let sid = socket.id;
                socket.emit('false', sid);

                let number = 10;

                let data = setInterval(() => {
                    if (number == 0) {
                        clearInterval(data);
                    } else {
                        number--;
                    }
                }, 1000)

                Swal.fire({
                    title: "[정류장콜]<br />새로운 콜이 도착했습니다.",
                    html: `<strong>${number}</strong>초 후 팝업닫힘`,
                    showCancelButton: true,
                    cancelButtonText: '닫기',
                    confirmButtonText: "받기",
                    didOpen: () => {
                        let num = 0;
                        const b = Swal.getHtmlContainer().querySelector('strong');
                        var timer = setInterval(() => {
                            b.textContent = `${number}`
                            if (number == 0) {
                                clearInterval(timer);
                                setCallCode("");
                                setStopName("");
                                setSentDate("");
                                setGetDate("");
                                setUrls("");
                                setRIds("");
                                Swal.close();
                                // let sid = socket.id;
                                // socket.emit('truth', sid);
                                // console.log("여기냐?2");
                            }
                            if (num > 100) {
                                clearInterval(timer);
                            }
                        }, 100)
                    }
                }).then((res) => {
                    // 통신 팝업 받기 버튼
                    if (res.isConfirmed) {
                        setModal(true);
                        setMenuBool(true);
                        clearInterval(data);
                        // 통신 팝업 닫기 버튼 => 통화끊김
                    } else if (res.dismiss == 'cancel') {
                        let cList = callList;
                        let filters = cList.filter((item) => item.stop_num != stopName);
                        setCallList(filters);
                        setCallCode("");
                        setStopName("");
                        setSentDate("");
                        setGetDate("");
                        setUrls("");
                        setRIds("");
                        let data = {
                            roomId: rIds,
                            stop_num: stopName,
                        }
                        socket.emit('ends', data);
                        offBtn(data);
                    } else if (res.isDismissed) {

                        socket.emit('truth', sid);
                    }
                });
            }
        }
    }, [urls])

    // 모달창 생성(modal == true일 때, 800,800 사이즈로 지정된 urls의 주소로 구루미 팝업 띄우기)
    useEffect(() => {
        if (modal) {
            const width = 800;
            const height = 800;
            const left = window.screenX + (window.outerWidth - width) / 2;
            const top = window.screenY + (window.outerHeight - height) / 2;

            // navigate("/main");
            // const popup = window.open(`https://biz.gooroomee.com/${urls}`, '팝업창', `width=${width},height=${height},left=${left},top=${top}`);
            const popup = window.open(`${urls}`, '팝업창', `width=${width},height=${height},left=${left},top=${top}`);
            setNewWin(popup);
        }
    }, [modal])

    // 팝업 닫혀있는지 확인 (닫혀있다면 소켓으로 닫혔다는 이벤트 송신 및 반복 이벤트 삭제)
    useEffect(() => {
        if (!newWin) {
            return;
        }

        let popupCheck = setInterval(function () {
            if (!newWin || newWin.closed) {
                // console.log('팝업닫힘');
                clearInterval(popupCheck);
                let data = {
                    roomId: rIds,
                    stop_num: stopName,
                }
                socket.emit('ends', data);
                let arr = callList;
                let filter = arr.filter((item) => item.stop_num != stopName);
                setCallList(filter);
                setModal(false);
                setUrls("");
            } else {
                // console.log(newWin);
            }
        }, 1000)

    }, [newWin])

    // 소켓을 통한 버스 데이터 확인
    useEffect(() => {
        if (posits != null) {
            console.log(posits);
            let arr = positArr;
            if (posits.state == 1) {
                let filterArr = arr.filter((item) => item.list_no == posits.list_no);
                if (filterArr.length > 0) {
                    let mapArr = arr.map((item) => item.list_no == posits.list_no ? { ...item, longitude: posits.longitude, latitude: posits.latitude } : item);
                    setPositArr(mapArr);
                } else {
                    setPositArr([...positArr, posits]);
                }
            } else {
                let filterArr = arr.filter((item) => item.list_no !== posits.list_no);
                if (filterArr.length > 0) {
                    setPositArr(filterArr);
                } else {
                    setPositArr([]);
                }
            }
        }
    }, [posits])

    useEffect(() => {
        if (positArr.length > 0) {
            console.log(positArr);
            let markers = [];

            let mapData = titleArr.filter((no) => no.route_name == sctTitle);
            if (mapData.length > 0) {
                let sctNum = mapData[0].no;

                let chkArr = positArr.filter((item) => item.route_no == sctNum);

                let positions = chkArr.map((item) => {
                    let data = new kakao.maps.LatLng(item.latitude, item.longitude);
                    return (
                        {
                            latlng: data
                        }
                    )
                })

                const imgSrc2 = bus;
                let imageSize2 = new kakao.maps.Size(30, 30);                         // 마커 이미지 사이즈
                let markerImage2 = new kakao.maps.MarkerImage(imgSrc2, imageSize2);    // 마커에 이미지 등록

                if (positMark.length > 0) {
                    let cnt = positMark.length;
                    for (let i = 0; i < cnt; i++) {
                        positMark[i].setMap(null);
                    }

                    if (positions.length > 0) {
                        for (let i = 0; i < positions.length; i++) {
                            var marker = new kakao.maps.Marker({
                                map: mapInfo,
                                position: positions[i].latlng,
                                image: markerImage2
                            })
                            markers.push(marker);
                        }
                        setPositMark(markers);
                    }

                } else {
                    if (positions.length > 0) {
                        for (let i = 0; i < positions.length; i++) {
                            var marker = new kakao.maps.Marker({
                                map: mapInfo,
                                position: positions[i].latlng,
                                image: markerImage2
                            })
                            markers.push(marker);
                        }
                        setPositMark(markers);
                    }
                }
            } else {
                if (positMark.length > 0) {
                    let cnt = positMark.length;
                    for (let i = 0; i < cnt; i++) {
                        positMark[i].setMap(null);
                    }
                }
            }

        } else {
            if (positMark.length > 0) {
                let cnt = positMark.length;
                for (let i = 0; i < cnt; i++) {
                    positMark[i].setMap(null);
                }
            }
        }
    }, [positArr])

    // 접수취소, 배차성공, 통화끊김, 수동배차 후 업무처리목록 refresh
    // 접수취소 -> 구루미 팝업 닫기, 서버 state 'true'로 변경, 버스 정류장 콜의 해당 정류장 데이터 삭제
    // 배차성공 -> 버스에 소켓 통신 , 구루미팝업 닫기, 서버 state 'true'로 변경, 버스 정류장 콜의 해당 정류장 데이터 삭제
    // 통화끊김 -> 서버 state 'true'로 변경
    // 수동배차 -> 버스에 소켓 통신
    const submitList = (data) => {
        // 통화끊김 x (접수 취소 , 배차 완료, 수동배차)
        if (data.status !== 'stop') {
            // 접수취소 x (배차완료, 수동배차)
            if (data.status !== "cancel") {
                let info = data;
                socket.emit('operate_bus', info);
            }

            // 구루미 팝업
            if (newWin) {
                // 구루미 팝업 열려있을 때 자동으로 닫히도록 설정
                if (!newWin.closed) {
                    newWin.close();
                    let datas = {
                        roomId: rIds,
                        stop_num: stopName,
                    }
                    socket.emit('ends', datas);
                }
            }
            // 수동배차 x (접수취소, 배차완료)
            if (data.status !== 'active') {
                let socketId = socket.id;
                socket.emit('truth', socketId);
                // console.log("여기냐?3");

                let call = callList;
                let newArr = call.filter((item) => item.stop_num !== data.onStop);
                setCallList(newArr);

                setStopName("");
                setSentDate("");
                setGetDate("");
                setRIds("");
            }
        } else {
            let socketId = socket.id;
            socket.emit('truth', socketId);
        }

        let sendData = {
            uniq: user.uniq
        };

        CommonAxios.CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/select_oper_list_today",
            sendData,
            function (result) {
                if (result.messageinfo.message != null) {
                    // console.log("업무처리 데이터 조회");
                    let getData = result.messageinfo.message;
                    setBusVal(getData);
                    setMenuBool(false);
                    setRunModal(false);
                } else {
                    Swal.fire({
                        title: "조회 오류",
                        text: result.messageinfo.message,
                        icon: "error",
                        confirmButtonText: "확인",
                        confirmButtonColor: "#FF0000",
                    });
                }
            }
        );
    }

    const cancelModal = () => {
        setRunModal(false);
    }

    useEffect(() => {
        menuBoolRef.current = menuBool;
        if (!menuBool) {
            if (startMark.length > 0) {
                startMark[0].setMap(null);
            }
            if (endMark.length > 0) {
                endMark[0].setMap(null);
            }
        }
    }, [menuBool])


    useEffect(() => {
        if (!runModal) {
            if (startMark.length > 0) {
                startMark[0].setMap(null);
            }
            if (endMark.length > 0) {
                endMark[0].setMap(null);
            }
        }
    }, [runModal])

    // 승차정류장 선택 이벤트(승차정류장 마크 생성 or 위치 변경, 하차정류장 마크 삭제)
    const startMarkOff = (data) => {

        let markers = [];

        const start_mark = start_icon;
        let imageSize = new kakao.maps.Size(25, 25);                         // 마커 이미지 사이즈
        let markerImage = new kakao.maps.MarkerImage(start_mark, imageSize);    // 마커에 이미지 등록

        if (startMark.length > 0) {
            startMark[0].setMap(null);
            if (endMark.length > 0) {
                endMark[0].setMap(null);
            }

            var startMarker = new kakao.maps.Marker({
                map: mapInfo,
                position: new kakao.maps.LatLng(data.y, data.x),
                image: markerImage
            })

            markers.push(startMarker);

            setStartMark(markers);

        } else {
            var startMarker = new kakao.maps.Marker({
                map: mapInfo,
                position: new kakao.maps.LatLng(data.y, data.x),
                image: markerImage
            })

            markers.push(startMarker);

            setStartMark(markers);
        }

    }

    // 하차정류장 선택 이벤트(하차정류장 마크 생성 or 이동)
    const endMarkOff = (data) => {

        let markers = [];

        const end_mark = end_icon;
        let imageSize = new kakao.maps.Size(25, 25);                         // 마커 이미지 사이즈
        let markerImage = new kakao.maps.MarkerImage(end_mark, imageSize);    // 마커에 이미지 등록

        if (endMark.length > 0) {
            endMark[0].setMap(null);

            var endMarker = new kakao.maps.Marker({
                map: mapInfo,
                position: new kakao.maps.LatLng(data.y, data.x),
                image: markerImage
            })

            markers.push(endMarker);

            setEndMark(markers);

        } else {
            var endMarker = new kakao.maps.Marker({
                map: mapInfo,
                position: new kakao.maps.LatLng(data.y, data.x),
                image: markerImage
            })

            markers.push(endMarker);

            setEndMark(markers);
        }

    }

    // 승차정류장 x버튼 클릭 이벤트 - 출발 마커 삭제
    const delStartOff = () => {
        if (startMark.length > 0) {
            startMark[0].setMap(null);
            setStartMark([]);
        }
    }

    // 하차정류장 x버튼 클릭 이벤트 - 도착 마커 삭제
    const delEndOff = () => {
        if (endMark.length > 0) {
            endMark[0].setMap(null);
            setEndMark([]);
        }
    }

    const columns = useMemo(() => [
        {
            Header: "콜 처리코드",
            accessor: "allocate_code",
        },
        {
            Header: "배차 분류",
            accessor: "allocate_category",
        },
        {
            Header: "배차 버스",
            accessor: "allocate_bus_no",
            Cell: ({ cell: { value } }) => <Bus value={value} />,
        },
        {
            Header: "처리",
            accessor: "allocate_state",
            Cell: ({ cell: { value } }) => <State value={value} />,
        },
    ]);

    const Bus = ({ value }) => {
        if (value == 0) {
            return ""
        } else {
            return value;
        }
    };

    const State = ({ value }) => {
        if (value == null) {
            return ""
        } else {
            if (value == 1) {
                return "배차전";
            } else if (value == 2) {
                return "배차 완료";
            } else if (value == 3) {
                return "접수 취소";
            } else if (value == 4) {
                return "통화 끊김";
            } else {
                return ""
            }
        }
    };

    const busCallFunc = (data) => {

        setCallCode(data.call_code)
        setUrlBool(true);
        setStopName(data.stop_num);
        setSentDate(data.date);
        setGetDate(new Date());
        setUrls(data.urls);
        setRIds(data.roomId);
        setModal(true);
        setMenuBool(true);

    }

    const offBtn = (data) => {
        // let nowDate = new Date();
        // let callCode = 'B' +  moment(nowDate).format('YYYYMMDDHHmmss');

        let sendData = {
            codeNum: callCode,
            uniq: user.uniq,
            // category: "정류장콜",
            // onStop: data.stop_num,
            // sendDate: moment(nowDate).format('YYYY-MM-DD HH-mm-ss'),
        }
        CommonAxios.CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/update_off_operate",
            sendData,
            function (result) {
                if (result.messageinfo.message != "실패") {
                    let data = {
                        status: 'stop'
                    }
                    submitList(data);
                    let sid = socket.id;
                    socket.emit('truth', sid);
                } else {
                    Swal.fire({
                        title: "통화 끊김 오류",
                        text: result.messageinfo.message,
                        icon: "error",
                        confirmButtonText: "확인",
                        confirmButtonColor: "#FF0000",
                    });
                }
            }
        );
    }

    // 수정 모달창 열기
    function openModal(i) {
        console.log(i);
        setModalData(i);
        setWorkModal(true);
    }

    // 모달창 닫기
    const onClose = () => {
        setWorkModal(false);
        setModalData("");
    }


    const callBtn = () => {
    }

    return (
        <div className={styles.main_container}>
            <Scrollbars className={styles.main_left_div} style={{ width: 361, height: 828 }}>
                <div className={styles.main_user_info}>
                    <div className={styles.user_info_area}>
                        <FaRegCircleUser size={36} color={"#004EA2"} />
                        <div className={styles.user_nickname} onClick={() => callBtn()}>{user.id}</div>
                        <div className={styles.user_id} >
                            아이디 {user.id}
                        </div>
                    </div>
                    <div className={styles.user_info_view}>
                        <span>내정보</span>
                        <MdKeyboardArrowRight size={14} color={"#004EA2"} />
                    </div>
                </div>
                <hr />
                {!menuBool ?
                    <Menu callList={callList} submitCall={busCallFunc} />
                    :
                    <CallMenu
                        onNum={stopName} callCode={callCode} sentDate={sentDate} getDate={getDate} nameArray={titleArr} stopsData={stopArr} allStop={allStop}
                        chkStart={startMarkOff} chkEnd={endMarkOff} delEnd={delEndOff} submit={submitList} user={user}
                    />
                }
                <hr />
                <div className={styles.main_call_info} style={menuBool ? { minHeight: "200px" } : { minHeight: "230px" }}>
                    <div className={styles.call_info_area}>
                        <div className={styles.call_info_box}>
                            <img src={calendar} />
                            <div className={styles.call_title}>오늘의 업무 처리 목록</div>
                            <div className={styles.call_type_box}>
                                <div className='bus' style={typeVal == "bus" ? { backgroundColor: "#004EA2" } : { backgroundColor: "#ccc" }} onClick={() => setTypeVal("bus")}>버스</div>
                                <div className='taxi' style={typeVal == "taxi" ? { backgroundColor: "#004EA2" } : { backgroundColor: "#ccc" }} onClick={() => setTypeVal("taxi")}>택시</div>
                            </div>
                        </div>
                        <div className={styles.call_info_view}>
                            <span>{busVal.length}</span>
                        </div>
                    </div>
                    <div className={styles.call_info_list}>
                        {typeVal == "bus" ?
                            <CustomSubTable columns={columns} data={busVal} sizeNum={!menuBool ? 10 : 5} onClickEvt={openModal} />
                            :
                            <CustomSubTable columns={columns} data={taxiVal} sizeNum={!menuBool ? 10 : 5} />
                        }
                    </div>
                </div>
            </Scrollbars>

            <div className={styles.main_right_div}>
                <div id="map" style={{ width: "100%", height: `calc(100% - 53px)` }}></div>
                <div className={styles.map_type_box}>
                    <div className='bus' style={mapType == "bus" ? { backgroundColor: "#004EA2" } : { backgroundColor: "#ccc" }} onClick={() => setMapType("bus")}>버스</div>
                    <div className='taxi' style={mapType == "taxi" ? { backgroundColor: "#004EA2" } : { backgroundColor: "#ccc" }} onClick={() => setMapType("taxi")}>택시</div>
                </div>
                {mapType == "bus" &&
                    <select className={styles.map_select_box} value={sctTitle} onChange={onChangeTitle}>
                        <option value={""}>노선 선택</option>
                        {titleArr.map((a) => {
                            return (
                                <option key={a.route_name} value={a.route_name}>{a.route_name}</option>
                            )
                        })}
                    </select>
                }
                <div className={styles.map_operate} onClick={() => setRunModal(!runModal)}>배차</div>
                {/* <div className={styles.map_call_btn} onClick={() => setMenuBool(!menuBool)}>
                    <img src={call_icon} />
                </div> */}

                {/* <div className={styles.map_call_btn}>
                    <img src={call_icon} />
                </div> */}
            </div>
            {runModal &&
                <RunModal
                    routeArray={stopArr} nameArray={titleArr} cancels={cancelModal} submit={submitList} user={user}
                    chkStart={startMarkOff} chkEnd={endMarkOff} delStart={delStartOff} delEnd={delEndOff}
                />
            }
            {workModal &&
                <WorkModal value={modalData} routeArray={stopArr} nameArray={titleArr} onClose={onClose} />
            }
        </div>
    )
}

export default MainPage;