import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react'
import { FaArrowLeft } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { Scrollbars } from 'react-custom-scrollbars';
import * as CommonAxios from "./../CommonAxios";
import styles from "./../../styles/main.module.css";
import moment from "moment";
import Swal from "sweetalert2";
// import phone_icon from "./../../assets/icons/phone_call.png";
// import bus_icon from "./../../assets/icons/bus_call.png";
// import taxi_icon from "./../../assets/icons/taxi_call.png";


const CallMenu = ({ onNum, callCode, sentDate, getDate, nameArray, stopsData, allStop, chkStart, chkEnd, delEnd, submit, user }) => {

    const [sDate, setSDate] = useState("");         //발신일자
    const [gDate, setGDate] = useState("");         //수신일자
    const [sName, setSName] = useState("");         //정류장번호
    const [codes, setCodes] = useState("");         //콜처리번호

    const [routeArr, setRouteArr] = useState([]);       // 노선 정보 배열
    const [nameArr, setNameArr] = useState([]);         // 버스(노선) 이름 배열
    const [stopsArr, setStopsArr] = useState([]);       // 정류장 배열

    const [sctOnNum, setSctOnNum] = useState("");        // 선택된 승차 정류장 번호
    const [sctOffNum, setSctOffNum] = useState("");      // 선택된 하차 정류장 번호

    // const [nameChkArr, setNameChkArr] = useState([]);   // 승차 정류장 선택시 그 정류장에서 갈 수 있는 모든 노선정보배열

    const [showNameArr, setShowNameArr] = useState([]); // 보여줄 노선 배열 데이터
    const [sctRoute, setSctRoute] = useState(0);

    const [onVal, setOnVal] = useState("");             // 승차 input 데이터
    const [offVal, setOffVal] = useState("");           // 하차 input 데이터

    const [memoVal, setMemoVal] = useState("");

    // const [oriOnArr, setOriOnArr] = useState([]);
    // const [onShowArr, setOnShowArr] = useState([]);
    const [oriOffArr, setOriOffArr] = useState([]);
    const [offShowArr, setOffShowArr] = useState([]);

    // const [onBool, setOnBool] = useState(false);
    const [offBool, setOffBool] = useState(false);

    const [userInfo, setUserInfo] = useState("");

    useEffect(() => {
        if (stopsData != undefined) {
            setRouteArr(stopsData);
        }
        if (nameArray != undefined) {
            setNameArr(nameArray);
        }
        setUserInfo(user);
        setSDate(sentDate);
        setGDate(getDate);
        setSName(onNum);
        setCodes(callCode);

        if (stopsData != undefined) {
            let callStop = stopsData.filter((a) => a.stop_number == onNum);

            // 중복된 정류장 고유번호 값을 기준으로 객체를 그룹화하고, 각 그룹에서 순번 값이 가장 작은 객체만 남김
            const uniqueArray = Object.values(callStop.reduce((acc, obj) => {
                const { rs_route_no, rs_order } = obj;
                if (!acc[rs_route_no] || acc[rs_route_no].rs_order > rs_order) {
                    acc[rs_route_no] = obj;
                }
                return acc;
            }, {}));

            const filterArray = stopsData.filter(item => {
                const match = uniqueArray.find(datas => datas.rs_route_no == item.rs_route_no);

                if (match) {
                    if (match.rs_order < item.rs_order) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            })
            setOriOffArr(filterArray);
            setOffShowArr(filterArray);

            // let newArr = stopsData.filter((a) => a.stop_number == onNum);
            let newArr = allStop.filter((a) => a.stop_number == onNum);
            setSctOnNum(onNum);
            if (newArr.length > 0) {
                setOnVal(`${newArr[0].stop_name} ${newArr[0].stop_number}`);
                // setStopsArr(stopsData);
                // setOriOnArr(stopsData);
                // setOnShowArr(stopsData);
                // setOffShowArr(stopsData);
                let sendData = {
                    x: newArr[0].stop_longitude,
                    y: newArr[0].stop_latitude,
                }
                chkStart(sendData);
            }
        }

    }, [])

    // useEffect(() => {
    //     if(sctOnNum != ""){
    //         let arr = routeArr;
    //         let ftArr = routeArr.filter((item) => item.stop_number == sctOnNum);
    //         let filter = arr.filter(stop => {
    //             for(const item of ftArr){
    //                 if(stop.rs_route_no == item.rs_route_no && stop.rs_order > item.rs_order){
    //                     return true;
    //                 }
    //             }
    //             return false;
    //         })
    //         setNameChkArr(filter);
    //         let numArr = filter.map((item) => item.stop_number);

    //         let onlyArr = stopsArr.filter(item => numArr.includes(item.stop_number));
    //         setOriOffArr(onlyArr);
    //         setOffShowArr(onlyArr);
    //         console.log(onlyArr);

    //         setOffVal("");
    //         setSctOffNum(0);
    //     }
    // }, [sctOnNum])

    // const onChgArr = (e) => {
    //     let data = e.target.value;
    //     setOnVal(data);
    // }

    // useEffect(() => {
    //     if(onVal != ""){
    //         if(stopsData.length > 0){
    //             let result = oriOnArr.filter((item) => 
    //                 String(item.stop_name).includes(onVal) ||
    //                 String(item.stop_number).includes(onVal)
    //             )
    //             setOnShowArr(result)
    //         }
    //     } else {
    //         if(stopsData.length > 0){
    //             setOnShowArr(oriOnArr);
    //         } else {
    //             setOnShowArr([]);
    //         }
    //     }
    // }, [onVal])

    // const sctOnStop = ({event, data}) => {
    //     let array = stopsArr;
    //     let ftArr = array.filter((item) => item.stop_number == data);
    //     setOnVal(`${ftArr[0].stop_name} ${data}`)
    //     setOnShowArr(ftArr);
    //     setSctOnNum(data);
    //     setOnBool(false);
    //     setShowNameArr([]);
    //     setSctRoute(0);

    //     let sendData = {
    //         x: ftArr[0].stop_longitude,
    //         y: ftArr[0].stop_latitude,
    //     }
    //     chkStart(sendData);
    // }

    const offChgArr = (e) => {
        let data = e.target.value;
        setOffVal(data);
    }

    useEffect(() => {
        if (offVal != "") {
            if (oriOffArr.length > 0) {
                // console.log(oriOffArr);
                let result = oriOffArr.filter((item) =>
                    String(item.stop_name).includes(offVal) ||
                    String(item.stop_number).includes(offVal)
                )
                setOffShowArr(result)
            }
        } else {
            if (oriOffArr.length > 0) {
                setOffShowArr(oriOffArr);
            }
        }
    }, [offVal])

    const sctOffStop = ({ event, data }) => {
        let array = offShowArr;
        let filters = array.filter((item) => item.stop_number == data);
        setOffVal(`${filters[0].stop_name} ${data}`)
        setSctOffNum(data);

        const filtered = nameArr.filter(item => {
            return filters.some(datas => datas.rs_route_no == item.no)
        })
        setShowNameArr(filtered);
        setOffBool(false);

        let sendData = {
            x: filters[0].stop_longitude,
            y: filters[0].stop_latitude,
        }
        chkEnd(sendData);
    }

    const onChangeRoute = useCallback((e) => {
        let getData = e.target.value;
        setSctRoute(getData);
    }, [])

    const onChangeMemo = useCallback((e) => {
        let getData = e.target.value;
        setMemoVal(getData);
    }, [])

    // const delSData = () => {
    //     delStart();
    //     setSctOnNum(0);
    //     setOnVal("");
    //     setSctOffNum(0);
    //     setOffVal("");
    //     setShowNameArr([]);
    //     setSctRoute(0);
    // }

    // const openOnBox = (e) => {
    //     e.stopPropagation();
    //     setOnBool(!onBool);
    // }

    const delEData = () => {
        delEnd();
        setSctOffNum(0);
        setOffVal("");
        setShowNameArr([]);
        setSctRoute(0);
    }

    const openOffBox = (e) => {
        e.stopPropagation();
        setOffBool(!offBool);
    }

    const closeBox = (e) => {
        // setOnBool(false);
        setOffBool(false);
    }

    const submitBtn = (e) => {
        e.preventDefault();
        let nowDate = new Date();

        let routes = routeArr.filter((item) => item.rs_route_no == sctRoute);

        let onNumber = routes.filter((item) => item.stop_number == sctOnNum);

        if (sctOnNum != "" && sctOffNum != "" && sctRoute != 0) {
            let sendData = {
                routeNo: sctRoute, // 노선고유번호
                codeNum: codes,
                category: 2,
                onStop: sctOnNum,
                offStop: sctOffNum,
                sendDate: moment(nowDate).format('YYYY-MM-DD HH-mm-ss'),
                order_num: onNumber[0].rs_order,
                routesArr: routeArr,
                memos: memoVal,
                uniq: userInfo.uniq
            }
            // console.log(sendData);
            CommonAxios.CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/update_bus_operate",
                sendData,
                function (result) {
                    if (result.messageinfo.message > 0) {
                        let number = result.messageinfo.message;
                        let record = result.messageinfo.message_data2;
                        let datas = {
                            busNum: number,
                            onStop: sctOnNum,
                            offStop: sctOffNum,
                            recordId: record,
                            status: "success",
                            callCode: codes,
                            uniq: userInfo.uniq,
                        }
                        Swal.fire({
                            title: "배차 성공",
                            icon: "success",
                            message: result.messageinfo.message,
                            confirmButtonText: "확인",
                        }).then((res) => {
                            if (res.isConfirmed) {
                                submit(datas);
                            }
                        });
                    } else if (result.messageinfo.message == "실패1") {
                        Swal.fire({
                            title: "배차 실패",
                            text: "해당 노선의 운행중인 버스가 없습니다.",
                            icon: "error",
                            confirmButtonText: "확인",
                            confirmButtonColor: "#FF0000",
                        });
                    } else if (result.messageinfo.message == "실패2") {
                        Swal.fire({
                            title: "배차 실패",
                            text: "해당 정거장에 배차가 가능한 버스가 없습니다.",
                            icon: "error",
                            confirmButtonText: "확인",
                            confirmButtonColor: "#FF0000",
                        });
                    } else {
                        Swal.fire({
                            title: "배차 오류",
                            text: result.messageinfo.message,
                            icon: "error",
                            confirmButtonText: "확인",
                            confirmButtonColor: "#FF0000",
                        });
                    }
                    // if (result.messageinfo.message != "실패") {
                    //     let number = result.messageinfo.message;
                    //     Swal.fire({
                    //         title: "배차 성공",
                    //         icon: "success",
                    //         message: result.messageinfo.message,
                    //         confirmButtonText: "확인",
                    //         }).then((res) => {
                    //             if (res.isConfirmed) {
                    //                 submit(number);
                    //             }
                    //         });
                    // } else {
                    //     Swal.fire({
                    //         title: "계획 수정 오류",
                    //         text:result.messageinfo.message,
                    //         icon: "error",
                    //         confirmButtonText: "확인",
                    //         confirmButtonColor: "#FF0000",
                    //     });
                    // }
                }
            );
        } else {

        }

    }

    const cancelBtn = (e) => {
        e.preventDefault();
        let nowDate = new Date();

        let sendData = {
            codeNum: codes,
            category: 2,
            onStop: sctOnNum,
            sendDate: moment(nowDate).format('YYYY-MM-DD HH-mm-ss'),
        }
        CommonAxios.CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/update_cancel_operate",
            sendData,
            function (result) {
                if (result.messageinfo.message != "실패") {
                    Swal.fire({
                        title: "배차 취소 완료",
                        icon: "success",
                        message: result.messageinfo.message,
                        confirmButtonText: "확인",
                    }).then((res) => {
                        if (res.isConfirmed) {
                            let datas = {
                                onStop: sctOnNum,
                                status: "cancel",
                            }
                            submit(datas);
                        }
                    });
                } else {
                    Swal.fire({
                        title: "배차 취소 오류",
                        text: result.messageinfo.message,
                        icon: "error",
                        confirmButtonText: "확인",
                        confirmButtonColor: "#FF0000",
                    });
                }
            }
        );
    }

    return (
        <>
            <div className={styles.main_call_info} style={{ minHeight: "40px" }} onClick={(e) => closeBox(e)}>
                <div className={styles.call_info_area} style={{ borderBottom: "0" }}>
                    <div className={styles.call_info_box}>
                        <FaArrowLeft onClick={cancelBtn} />
                        <div className={styles.call_title}>정류장콜 상세정보</div>
                    </div>
                </div>
            </div>
            <hr />
            <div className={styles.main_call_info_div} onClick={(e) => closeBox(e)}>
                <div>
                    <div>발신 번호</div>
                    <div>{sName}</div>
                </div>
                <div>
                    <div>발신 일시</div>
                    <div>{moment(sDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                </div>
                <div>
                    <div>수신 일시</div>
                    <div>{moment(gDate).format('YYYY-MM-DD HH:mm:ss')}</div>
                </div>
            </div>
            <hr />
            <div className={styles.main_call_handle_div} onClick={(e) => closeBox(e)}>
                <div className={styles.call_info_area}>
                    <div className={styles.call_info_box}>
                        <div className={styles.call_title}>업무 처리</div>
                    </div>
                </div>
                <div className={styles.handle_list_div}>
                    <div>
                        <div>승차정류장</div>
                        <div className={styles.list_area}>
                            <input type="text" readOnly value={onVal} style={{ width: "180px" }} />
                            {/* {onVal &&
                            <IoMdCloseCircle style={{padding: "0 5px"}} onClick={() => delSData()} />
                            } */}
                            {/* <FaMagnifyingGlass style={{float:"right", margin:"2px 0"}} onClick={(e) => openOnBox(e)} /> */}
                            {/* {onBool &&
                                <ul>
                                    {onShowArr.length > 0 ?
                                        (onShowArr.length > 5 ?
                                            <Scrollbars className={styles.ul_area} style={{ width: 198, height: 150 }}>
                                            {onShowArr.map((a) => {
                                                return(
                                                    <li onClick={(e) => sctOnStop({event: e, data: a.stop_number})}>
                                                        <h2>{a.stop_name}{" "}{a.stop_number}</h2>
                                                    </li>
                                                )
                                            })}
                                            </Scrollbars>
                                            :
                                            onShowArr.map((a) => {
                                                return(
                                                    <li onClick={(e) => sctOnStop({event: e, data: a.stop_number})}>
                                                        <h2>{a.stop_name}{" "}{a.stop_number}</h2>
                                                    </li>
                                                )
                                            })
                                        )
                                        :
                                        <li style={{height:"30px", textAlign:"center", lineHeight:"30px"}}>조회된 결과가 없습니다.</li>
                                    }
                                </ul>
                            } */}
                        </div>
                    </div>
                    <div>
                        <div>하차정류장</div>
                        <div className={styles.list_area}>
                            <input type="text" value={offVal} placeholder='정류장 이름/번호 입력' onChange={(e) => offChgArr(e)} />
                            {offVal &&
                                <IoMdCloseCircle onClick={() => delEData()} />
                            }
                            <FaMagnifyingGlass style={{ float: "right", margin: "6px 0" }} onClick={(e) => openOffBox(e)} />
                            {offBool &&
                                <ul>
                                    {offShowArr.length > 0 ?
                                        (offShowArr.length > 4 ?
                                            <Scrollbars className={styles.ul_area} style={{ width: 198, height: 150 }}>
                                                {offShowArr.map((a) => {
                                                    return (
                                                        <li onClick={(e) => sctOffStop({ event: e, data: a.stop_number })}>
                                                            <h2>{a.stop_name}{" "}{a.stop_number}</h2>
                                                        </li>
                                                    )
                                                })}
                                            </Scrollbars>
                                            :
                                            offShowArr.map((a) => {
                                                return (
                                                    <li onClick={(e) => sctOffStop({ event: e, data: a.stop_number })}>
                                                        <h2>{a.stop_name}{" "}{a.stop_number}</h2>
                                                    </li>
                                                )
                                            })
                                        )
                                        :
                                        <li style={{ height: "30px", textAlign: "center", padding: "3px" }}>조회된 결과가 없습니다.</li>
                                    }
                                </ul>
                            }
                        </div>
                    </div>
                    <div>
                        <div>노선</div>
                        <select className={styles.list_area} style={{ width: "210px" }} value={sctRoute} onChange={onChangeRoute}>
                            <option value={0}>노선을 선택하세요.</option>
                            {showNameArr.map((a) => {
                                return (
                                    <option key={a.no} value={a.no}>{a.route_name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <div>메모</div>
                    </div>
                    <div>
                        <textarea value={memoVal} placeholder='최대 200자' style={{ width: "100%" }} rows={5} onChange={onChangeMemo} />
                    </div>
                    <div className={styles.button_area}>
                        <button className={styles.cancel_btn} onClick={cancelBtn}>접수 취소</button>
                        <button className={styles.comp_btn} onClick={submitBtn}>배차완료</button>
                    </div>
                </div>
            </div>
        </>
    )


}

export default CallMenu;