
import { useEffect, useState } from "react";
import manage from "../../styles/moniter.module.css"
import { CommonAxios } from "../../module/CommonAxios";
import MoniterItem from "./MoniterItem";
import io from 'socket.io-client';

const MoniterIn = () => {
    const [waitCnt, setWaitCnt] = useState(0);
    const [raceCnt, setRaceCnt] = useState(0);
    const [routeData, setRouteData] = useState([]);
    const [busList, setBusList] = useState([]);
    const [socket, setSocket] = useState();
    const [sw, setSw] = useState(false);
    useEffect(() => {
        const newSocket = io.connect('http://yjview.kr:80');
        setSocket(newSocket);


        let sendData = {
            no: ""
        };



        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/moniter/select_bus_moniter",
            sendData,
            function (result) {
                if (result.messageinfo) {

                    let raceCout = 0;
                    let waitCout = 0;
                    let arr = [[]];
                    setRouteData(result.messageinfo.message_data2)
                    result.messageinfo.message_data2.map((data) => {


                        raceCout = raceCout + data.race;
                        waitCout = waitCout + data.wai

                        // arr[0][0].push(data)
                        // for (let i = 0; i < result.messageinfo.message.length; i++) {
                        //     if (result.messageinfo.message[i].rs_route_no === data.no) {
                        //         arr[0][1].push(result.messageinfo.message[i])
                        //     }
                        // }
                        // result.messageinfo.message.map((data2) => {
                        //     if (data.no === data2.rs_route_no) {
                        //         arr[1].push(data2)
                        //     }
                        // })
                        // setWaitCnt(data.wai)

                    })


                    // setRaceCnt(raceCout);
                    // setWaitCnt(waitCout);
                    result.messageinfo.message.map((data2) => {

                        data2['order'] = 0;
                        data2['state'] = 0;

                        setBusList(busList => [...busList, data2])
                    })
                    // setBusList(result.messageinfo.message);
                    setSw(true);
                }
            })
        return () => {
            newSocket.disconnect();
            setSocket(null);
        }
    }, [])
    const [posits, setPosits] = useState([]);
    const [socketArr, setSocketArr] = useState([]);
    const [count, setCount] = useState(0);


    const Arr = async (ro) => {


        setBusList(busList.map((item) => Number(item.no) === Number(ro.list_no) ? { ...item, 'order': ro.driver_order, "state": 1 } : item));

    }
    useEffect(() => {

        if (!socket) return;



        socket.on('connect', () => {
            console.log("연결완료!");


        });

        socket.on('position', ro => {


            // Arr(ro);
            // console.log(busList)
            // setBusList(busList.map((item) => Number(item.no) === Number(ro.list_no) ? { ...item, 'order': ro.driver_order } : item));

            setTimeout(() => {
                setPosits(ro);
            }, 3000)
        })

    }, [socket, sw])



    useEffect(() => {



        // if (posits.length > 0) {
        // for (let i = 0; i < posits.length; i++) {

        setBusList(busList.map((item) => Number(item.no) === Number(posits.list_no) ? { ...item, 'order': posits.driver_order, "state": 1 } : item));
        cnt = 0;
        not = 0;
        // let count
        // busList.map((item)=>{

        //     if(item.state===1){

        //     }else{

        //     }
        // })



        // setRaceCnt(raceCout);
        // setWaitCnt(waitCout);
        // console.log(posits.list_no)
        // }
        // }
        // if (socketArr.length > 0) {
        //     console.log(socketArr)
        //     let notData = socketArr.filter((item) => { return item.list_no === ro.list_no });

        //     if (notData.length > 0) {
        //         setSocketArr(socketArr.filter((item, i) => {
        //             return item.list_no !== ro.list_no;
        //         }));
        //         setSocketArr(socketArr => [...socketArr, ro]);

        //     } else {



        //         setSocketArr(socketArr => [...socketArr, ro]);
        //     }

        // } else {
        //     // console.log(ro);
        //     setSocketArr(socketArr => [...socketArr, ro]);
        // }



    }, [posits])

    // useEffect(() => {

    //     if (!socket) return;
    //     socket.on('position', ro => {

    //         // setPosits(ro);

    //         if (socketArr.length > 0) {
    //             console.log(socketArr)
    //             let notData = socketArr.filter((item) => { return item.list_no === ro.list_no });

    //             if (notData.length > 0) {
    //                 setSocketArr(socketArr.filter((item, i) => {
    //                     return item.list_no !== ro.list_no;
    //                 }));
    //                 setSocketArr(socketArr => [...socketArr, ro]);

    //             } else {



    //                 setSocketArr(socketArr => [...socketArr, ro]);
    //             }

    //         } else {
    //             // console.log(ro);
    //             setSocketArr(socketArr => [...socketArr, ro]);
    //         }

    //         // console.log(socketArr);
    //     })
    // }, [socket,])

    let cnt = 0;
    let not = 0;
    const TopView = ({ busList }) => {

        // useEffect(()=>{

        //     {busList.map((item,index)=>{
        //         if(item.state===1){

        //         }
        //     })}
        // },[busList])

        return (
            <div>
                <ul>
                    <li><span>운행중</span><span>
                        {busList.map((item, index) => {

                            if (item.state === 1) {
                                cnt = cnt + 1;
                            }
                            if (index === busList.length - 1) {
                                return cnt;
                            }

                        })}
                    </span></li>
                    <li><span>대기중</span><span>
                        {busList.map((item, index) => {

                            if (item.state === 0) {
                                not = not + 1;
                            }
                            if (index === busList.length - 1) {
                                return not;
                            }

                        })}</span></li>
                </ul>
            </div>
        )
    }

    return <div className={manage.moniter_wrap}>
        <div className={manage.moniter_box}>
            <div className={manage.moniter_top}>
                <TopView busList={busList} />
            </div>
            <div className={manage.moniter_middle}>
                <div>
                    {routeData.map((item) => {
                        return <MoniterItem data={item} busList={busList} />
                    })}


                </div>
            </div>
        </div>
    </div >
}

export default MoniterIn